import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const ImageRenderer = ({ img, alt, width, height }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(png|jpe?g|gif|svg)/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
      }
    }
  `)

  const imageNode = data.allFile.nodes.find(node => node.name === img)

  let ImageComponent
  if (imageNode?.childImageSharp) {
    ImageComponent = (
      <GatsbyImage
        image={imageNode.childImageSharp.gatsbyImageData}
        alt={alt ? alt : ""}
      />
    )
  } else if (imageNode?.publicURL) {
    ImageComponent = (
      <img
        src={imageNode.publicURL}
        alt={alt ? alt : ""}
        width={height ? width : ""}
        height={height ? height : ""}
      />
    )
  }

  return ImageComponent
}

export default ImageRenderer
