import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ImageRenderer from "../components/atoms/ImageRenderer"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"

const UserEmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState(null) // Holds the success or error message
  const location = useLocation() // Get the current location
  const searchParams = new URLSearchParams(location.search) // Parse query parameters
  const token = searchParams.get("token")
  const enviroment = searchParams.get("enviroment")

  let api_base_url = ""

  if (enviroment === "production") {
    api_base_url = `https://api.synkli.com.au/api/auth/verify-registering-email`
  } else if (enviroment === "staging") {
    api_base_url = `https://stg-api.synkli.dev/api/auth/verify-registering-email`
  } else if (enviroment === "development") {
    api_base_url = `https://api.synkli.dev/api/auth/verify-registering-email`
  } else if (enviroment === "preproduction") {
    api_base_url = `https://preprod-api.synkli.com.au/api/auth/verify-registering-email`
  } else {
    api_base_url = `https://api.synkli.dev/api/auth/verify-registering-email`
  }

  const [redirectUrl, setRedirectUrl] = useState("")

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Check if the device is Android, iPhone, iPad, or iPod
    if (/android|iPhone|iPad|iPod/i.test(userAgent)) {
      setRedirectUrl("https://synkli.com.au/auth/")
    } else {
      setRedirectUrl("https://partners.synkli.dev/signup-email/link-verify/")
    }

    if (!token) {
      setVerificationStatus("error") // If token is missing, set status to error
      return
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(api_base_url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
          }),
        })

        const result = await response.json()
        if (response.ok) {
          setVerificationStatus("success") // Set status to success if API call was successful
        } else {
          setVerificationStatus("error") // Set status to error if the API response was an error
        }
      } catch (error) {
        setVerificationStatus("error") // Handle any network or unexpected errors
      }
    }

    verifyEmail() // Call the function when the component mounts
  }, [token]) // Add token as a dependency to ensure the effect runs if it changes

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Seo title="User Email Verification" />
      <div className="bg-[#FAF9F6] min-h-[100vh] px-[20px]">
        <div className="synkli-section--horizontal-space flex flex-wrap items-center justify-between pt-6 sm:pt-10 pb-4 !px-0">
          <div className="w-[110px]">
            <Link to="/">
              <ImageRenderer
                img={`synkli-logo-transparent`}
                alt={"synkli-logo-transparent"}
              />
            </Link>
          </div>
        </div>
        <div className="bg-white min-h-[70vh] rounded-[18px] text-center border-b-[20px] border-[#101828] max-w-[950px] mx-auto mt-5 py-14 px-5">
          {verificationStatus === "success" ? (
            <>
              <div className="max-w-[110px] mx-auto">
                <ImageRenderer
                  img={`synkli-user-email-verification-success`}
                  alt={`synkli-user-email-verification-success`}
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Email Verified Successfully!
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">Thank you!</span> Your email
                has been successfully verified. You can now return to your
                application Synkli.
              </p>
              {enviroment === "development" ? (
                <a
                  href={redirectUrl}
                  className="block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-2 transition duration-300 primary-btn inline-block"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Return to Your Application
                </a>
              ) : null}
            </>
          ) : verificationStatus === "error" ? (
            <>
              <div className="max-w-[110px] mx-auto">
                <ImageRenderer
                  img={`synkli-user-email-verification-failed`}
                  alt={`synkli-user-email-verification-failed`}
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Email Verification Failed
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">We're sorry!</span> The link
                you used is either invalid or has expired. Please try verifying
                your email again or contact our support team for assistance.
              </p>
              {enviroment === "development" ? (
                <a
                  href={redirectUrl}
                  className="block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-2 transition duration-300 primary-btn inline-block"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Return to Your Application
                </a>
              ) : null}
            </>
          ) : (
            <>
              <div className="max-w-[140px] mx-auto mb-5">
                <ImageRenderer img={`synkli-loader`} alt={`synkli-loader`} />
              </div>
              <p className="text-[18px] text-[#101828]">
                Verifying your email...
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default UserEmailVerification
